var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"template",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":36}}}),depth0,{"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer class=\"footer-wrapper\">\n    <div class=\"resume footer-top\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(helpers,"lookup").call(alias1,(depth0 != null ? lookupProperty(depth0,"resume") : depth0),"template",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":38}}}),(depth0 != null ? lookupProperty(depth0,"resume") : depth0),{"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <span id=\"count\" class=\"count\"></span>\n    </div>\n    <div class=\"socials footer-bottom\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"socials") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":17}}})) != null ? stack1 : "")
    + "        </p>\n    </div>\n</footer>";
},"usePartial":true,"useData":true});